<template lang="html">
  <div class="">
    <div class="main-container">
      <div class="h2">
        <button class="btn-back" type="button" name="button" @click="$router.go(-1)">
          <span class="icon-w-back"></span>
        </button>
        <span>修改密码</span>
      </div>
      <!-- <div class="text-help m-t">
        Note: 修改密码功能将在稍后版本中支持
      </div> -->
      <form @submit.prevent="onSubmit">
      <div class="" v-if="userInfo">
        <div class="">
          <label class="">新密码</label>
          <input class="form-control-p" type="password" name="" v-model="form.pwd" placeholder="密码">
        </div>
        <div class="m-t">
          <label>确认密码</label>
          <input class="form-control-p" type="password" v-model="form.repwd" placeholder="确认密码">
        </div>
      </div>
      <div class="btn-group m-t">
        <button type="submit" class="btn btn-success btn-md">确定修改</button>
      </div>
      </form>
      <div class="success-msg m-t" v-if="message">
        {{message}}
      </div>
      <div class="text-danger m-t" v-if="err">
        {{err}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AccountService } from '../../service/AccountService';

export default {
  data: () => ({
    message: '',
    err: '',
    form: {
      pwd: '',
      repwd: '',
    },
  }),
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    reset() {
      this.err = '';
      this.message = '';
      this.form = {
        pwd: '',
        repwd: '',
      };
    },
    onSubmit() {
      console.log('submit', this.form);
      AccountService.changePassword({
        pwd: this.form.pwd,
        repwd: this.form.repwd,
      }).then((res) => {
        const { code, msg } = res.data;
        if (code !== 200) {
          this.err = msg;
        } else {
          this.reset();
          this.message = '修改成功';
        }
        console.log(code, msg);
      });
    },
  },
};
</script>

<style lang="css" scoped>
.info-avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.info-avatar > img {
  width: 100%;
}
</style>
